// src/components/RegistrationModal.js

import React, { useState, useEffect } from 'react';
import { Box, Button, TextInput, FormField, Heading, Layer, Text, Image } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, signupWithGoogle } from '../redux/actions/authActions';
import googleLogo from "../assets/Google.webp"

const RegistrationModal = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const registrationToken = useSelector(state => state.auth.token); // Track token for success
  const [shopName, setShopName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Clear error and call onSuccess if registration is successful
  useEffect(() => {
    if (registrationToken) {
      setError('');
      onSuccess();
    }
  }, [registrationToken, onSuccess]);

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Password validation function (at least 6 characters)
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleRegister = async () => {
    if (!shopName) {
      setError('Shop Name is required');
      return;
    }
    if (!validateEmail(email)) {
      setError('Please enter a valid email');
      return;
    }
    if (!validatePassword(password)) {
      setError('Password must be at least 6 characters');
      return;
    }

    try {
      const formData = { shopName, email, password };
      await dispatch(registerUser(formData));
      if (!registrationToken) {
        setError('Registration failed. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };


  const handleGoogleRegister = async () => {
    if (!shopName) {
      setError('Shop Name is required');
      return;
    }
    try {
      const formData = { shopName, email, password };
      await dispatch(signupWithGoogle(formData));
      if (!registrationToken) {
        setError('Registration failed. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };


  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium" width="medium">
        <Heading level="3" margin="none">Register Your Shop</Heading>

        {error && <Text color="status-critical" margin={{ bottom: 'small' }}>{error}</Text>}

        <FormField label="Shop Name">
          <TextInput
            placeholder="Enter your shop name"
            value={shopName}
            onChange={(event) => setShopName(event.target.value)}
          />
        </FormField>
        <FormField label="Email">
          <TextInput
            placeholder="Enter your email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            type="email"
          />
        </FormField>
        <FormField label="Password">
          <TextInput
            type="password"
            placeholder="Create a password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormField>

        <Box direction="row" gap="small" margin={{ top: "medium" }}>
          <Button label="Register" primary onClick={handleRegister} />
          <Button label="Cancel" onClick={onClose} />
        </Box>
      </Box>

      <Box align="center" margin={{ top: "medium" }}>
        <Button onClick={handleGoogleRegister} plain>
          <Box direction="row" align="center" gap="small" pad="small" border={{ color: 'light-4', size: 'small' }}>
            <Image src={googleLogo} alt="Google logo" style={{ width: '80px', height: '50px' }} />
            <Text>Google signup</Text>
          </Box>
        </Button>
      </Box>
    </Layer>
  );
};

export default RegistrationModal;
