// src/redux/reducers/portalStatsReducer.js

import {
    FETCH_PORTAL_STATS_REQUEST,
    FETCH_PORTAL_STATS_SUCCESS,
    FETCH_PORTAL_STATS_FAILURE,
  } from '../actions/portalStatsActions';
  
  const initialState = {
    loading: false,
    portalStats: {
      registeredShops: 0,
      citiesCovered: 0,
      countriesCovered: 0,
      numberOfUsers: 0,
      printoutsTaken: 0,
    },
    error: null,
  };
  
  const portalStatsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PORTAL_STATS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_PORTAL_STATS_SUCCESS:
        return {
          ...state,
          loading: false,
          portalStats: action.payload,
        };
      case FETCH_PORTAL_STATS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default portalStatsReducer;
  