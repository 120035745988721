import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from '../actions/authActions';

// Initial state for the authentication
const initialState = {
  loading: false,
  user: null,
  token: null,
  shopkeeperData: null,
  error: null,
};

// Auth Reducer
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // Handle registration request
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // Handle successful registration
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
        shopkeeperData: action.payload.shopkeeperData,
        error: null,
      };

    // Handle registration failure
    case REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Handle login request
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // Handle successful login
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
        shopkeeperData: action.payload.shopkeeperData,
        error: null,
      };

    // Handle login failure
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Handle logout
    case LOGOUT:
      return {
        ...initialState,  // Reset to initial state on logout
      };

    // Default case
    default:
      return state;
  }
};

export default authReducer;
