// src/redux/reducers/printerReducer.js

import {
    FETCH_PRINTERS_REQUEST,
    FETCH_PRINTERS_SUCCESS,
    FETCH_PRINTERS_FAILURE,
    ADD_PRINTER_REQUEST,
    ADD_PRINTER_SUCCESS,
    ADD_PRINTER_FAILURE,
    UPDATE_PRINTER_CONFIG_REQUEST,
    UPDATE_PRINTER_CONFIG_SUCCESS,
    UPDATE_PRINTER_CONFIG_FAILURE,
    CHECK_PRINTER_HEALTH_REQUEST,
    CHECK_PRINTER_HEALTH_SUCCESS,
    CHECK_PRINTER_HEALTH_FAILURE,
    REMOVE_PRINTER_REQUEST,
    REMOVE_PRINTER_SUCCESS,
    REMOVE_PRINTER_FAILURE
  } from '../actions/printerActions';
  
  const initialState = {
    printers: [],
    loading: false,
    error: null,
  };
  
  const printerReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PRINTERS_REQUEST:
      case ADD_PRINTER_REQUEST:
      case UPDATE_PRINTER_CONFIG_REQUEST:
      case CHECK_PRINTER_HEALTH_REQUEST:
        return { ...state, loading: true, error: null };
  
      case FETCH_PRINTERS_SUCCESS:
        return { ...state, loading: false, printers: action.payload };
  
      case ADD_PRINTER_SUCCESS:
        return { ...state, loading: false, printers: [...state.printers, action.payload] };
  
      case UPDATE_PRINTER_CONFIG_SUCCESS:
        return {
          ...state,
          loading: false,
          printers: state.printers.map((printer) =>
            printer.printerId === action.payload.printerId ? action.payload : printer
          ),
        };
  
      case CHECK_PRINTER_HEALTH_SUCCESS:
        return {
          ...state,
          loading: false,
          printers: state.printers.map((printer) =>
            printer.printerId === action.payload.printerId
              ? { ...printer, status: action.payload.healthStatus }
              : printer
          ),
        };
  
      case FETCH_PRINTERS_FAILURE:
      case ADD_PRINTER_FAILURE:
      case UPDATE_PRINTER_CONFIG_FAILURE:
      case CHECK_PRINTER_HEALTH_FAILURE:
        return { ...state, loading: false, error: action.payload };

        case REMOVE_PRINTER_REQUEST:
            return { ...state, loading: true, error: null };
      
          case REMOVE_PRINTER_SUCCESS:
            return {
              ...state,
              loading: false,
              printers: state.printers.filter(
                (printer) => printer.printerId !== action.payload
              ),
            };
      
          case REMOVE_PRINTER_FAILURE:
            return { ...state, loading: false, error: action.payload };
      
  
      default:
        return state;
    }
  };
  
  export default printerReducer;
  