// src/services/MockJobQueueService.js

export const MockJobQueueService = {
    jobs: [],  // Holds the current jobs in the queue
    jobIdCounter: 1,
  
    // Initialize the job queue with some mock data
    initializeJobs: function () {
      this.jobs = [];
      this.jobIdCounter = 1;
      for (let i = 0; i < 5; i++) {
        this.addRandomJob();
      }
    },
  
    // Simulate adding a new job to the queue
    addRandomJob: function () {
      if (this.jobs.length >= 20) return; // Max 20 jobs
  
      const isFromGmail = Math.random() > 0.5;
      const isPaid = !isFromGmail ? Math.random() > 0.5 : false; // Gmail jobs are always unpaid
  
      const userNames = ['Alice', 'Bob', 'Carol', 'David', 'Eve']; // Example names
      const randomUserName = userNames[Math.floor(Math.random() * userNames.length)];
  
      const job = {
        id: this.jobIdCounter++,
        source: isFromGmail ? 'Gmail' : 'App',
        isPaid: isPaid,
        state: isPaid ? 'Waiting' : 'Unpaid',
        documentName: `Document_${this.jobIdCounter}`,
        receivedAt: new Date(),
        userName: randomUserName, // Adding the user's name
      };
  
      this.jobs.push(job);
    },
  
    // Simulate job state transitions
    updateJobStates: function () {
      this.jobs = this.jobs.map((job) => {
        if (job.isPaid && job.state === 'Waiting') {
          return { ...job, state: 'Printing' };
        } else if (job.isPaid && job.state === 'Printing') {
          return { ...job, state: 'Complete' };
        } else if (!job.isPaid && job.state === 'Unpaid') {
          // Unpaid jobs will be removed after some time
          const timeSinceReceived = (new Date() - job.receivedAt) / 1000; // in seconds
          if (timeSinceReceived > 60) { // Remove after 60 seconds
            return null;
          }
        }
        return job;
      }).filter(Boolean); // Remove null jobs
    },
  
    // Manually print an unpaid job
    manuallyPrintJob: function (jobId) {
      const jobIndex = this.jobs.findIndex((job) => job.id === jobId);
      if (jobIndex > -1) {
        this.jobs[jobIndex].isPaid = true;
        this.jobs[jobIndex].state = 'Waiting';
      }
    },
  
    // Fetch current jobs
    fetchJobs: function () {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([...this.jobs]); // Return a copy of the jobs array
        }, 500);
      });
    },
  };
  