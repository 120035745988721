// src/redux/reducers/subscriptionReducer.js

import {
    FETCH_SUBSCRIPTION_STATUS_REQUEST,
    FETCH_SUBSCRIPTION_STATUS_SUCCESS,
    FETCH_SUBSCRIPTION_STATUS_FAILURE,
    FETCH_AVAILABLE_PLANS_REQUEST,
    FETCH_AVAILABLE_PLANS_SUCCESS,
    FETCH_AVAILABLE_PLANS_FAILURE,
    SUBSCRIBE_REQUEST,
    SUBSCRIBE_SUCCESS,
    SUBSCRIBE_FAILURE,
    EXTEND_SUBSCRIPTION_REQUEST,
    EXTEND_SUBSCRIPTION_SUCCESS,
    EXTEND_SUBSCRIPTION_FAILURE,
  } from '../actions/subscriptionActions';
  
  // Initial State
  const initialState = {
    subscriptionStatus: null,  // Initialize as null or appropriate default value
    availablePlans: [],
    loading: false,
    error: null,
  };
  
  // Subscription Reducer
  const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SUBSCRIPTION_STATUS_REQUEST:
      case FETCH_AVAILABLE_PLANS_REQUEST:
      case SUBSCRIBE_REQUEST:
      case EXTEND_SUBSCRIPTION_REQUEST:
        return { ...state, loading: true, error: null };
  
      case FETCH_SUBSCRIPTION_STATUS_SUCCESS:
        return { ...state, subscriptionStatus: action.payload, loading: false };
  
      case FETCH_AVAILABLE_PLANS_SUCCESS:
        return { ...state, availablePlans: action.payload, loading: false };
  
      case SUBSCRIBE_SUCCESS:
      case EXTEND_SUBSCRIPTION_SUCCESS:
        return { ...state, subscriptionDetails: action.payload, loading: false };
  
      case FETCH_SUBSCRIPTION_STATUS_FAILURE:
      case FETCH_AVAILABLE_PLANS_FAILURE:
      case SUBSCRIBE_FAILURE:
      case EXTEND_SUBSCRIPTION_FAILURE:
        return { ...state, error: action.payload, loading: false };
  
      default:
        return state;
    }
  };
  
  export default subscriptionReducer;
  