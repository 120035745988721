// src/services/MockPaymentService.js

export const MockPaymentService = {
    // Mock payment functionality with a 20% chance of failure
    pay: async (amount, planType, shopkeeperDetails) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const paymentResponse = {
            paymentId: `payment_${Math.random().toString(36).substring(7)}`,  // Generate random payment ID
            amount: amount,
            shopkeeper: shopkeeperDetails,
            planType: planType,
            status: 'success',
          };
  
          // Simulate 20% chance of payment failure
          if (Math.random() > 0.2) {  // 80% success rate
            resolve(paymentResponse);
          } else {
            reject({ ...paymentResponse, status: 'failed', reason: 'Payment gateway error' });
          }
        }, 1500);  // Simulate payment processing delay
      });
    },
  
    // Mock handling payment success
    handlePaymentSuccess: async (paymentResponse) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          // Simulate successful payment processing
          const paymentDetails = {
            paymentId: paymentResponse.paymentId,
            amount: paymentResponse.amount,
            status: 'success',
            timestamp: new Date().toISOString(),
          };
          resolve(paymentDetails);
        }, 1000);
      });
    },
  
    // Mock handling payment failure
    handlePaymentFailure: async (paymentResponse) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // Simulate payment failure processing
          const failureDetails = {
            paymentId: paymentResponse.paymentId,
            status: 'failed',
            reason: paymentResponse.reason || 'Insufficient funds',  // Example failure reason
            timestamp: new Date().toISOString(),
          };
          reject(failureDetails);
        }, 1000);
      });
    },
  };
  