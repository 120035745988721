// src/redux/actions/subscriptionActions.js
import ServiceLoader from '../../services/ServiceLoader';

// Action Types
export const FETCH_SUBSCRIPTION_STATUS_REQUEST = 'FETCH_SUBSCRIPTION_STATUS_REQUEST';
export const FETCH_SUBSCRIPTION_STATUS_SUCCESS = 'FETCH_SUBSCRIPTION_STATUS_SUCCESS';
export const FETCH_SUBSCRIPTION_STATUS_FAILURE = 'FETCH_SUBSCRIPTION_STATUS_FAILURE';

export const FETCH_AVAILABLE_PLANS_REQUEST = 'FETCH_AVAILABLE_PLANS_REQUEST';
export const FETCH_AVAILABLE_PLANS_SUCCESS = 'FETCH_AVAILABLE_PLANS_SUCCESS';
export const FETCH_AVAILABLE_PLANS_FAILURE = 'FETCH_AVAILABLE_PLANS_FAILURE';

export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE';

export const EXTEND_SUBSCRIPTION_REQUEST = 'EXTEND_SUBSCRIPTION_REQUEST';
export const EXTEND_SUBSCRIPTION_SUCCESS = 'EXTEND_SUBSCRIPTION_SUCCESS';
export const EXTEND_SUBSCRIPTION_FAILURE = 'EXTEND_SUBSCRIPTION_FAILURE';

const subscriptionService = ServiceLoader.getSubscriptionService()


// Fetch the current subscription status for a shopkeeper
export const fetchSubscriptionStatus = (uid) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SUBSCRIPTION_STATUS_REQUEST });
    const status = await subscriptionService.getSubscriptionStatus(uid);
    dispatch({ type: FETCH_SUBSCRIPTION_STATUS_SUCCESS, payload: status });
  } catch (error) {
    dispatch({ type: FETCH_SUBSCRIPTION_STATUS_FAILURE, payload: error.message });
  }
};

// Fetch the available subscription plans for a shopkeeper
export const fetchAvailablePlans = (uid) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_AVAILABLE_PLANS_REQUEST });
    const plans = await subscriptionService.getAvailablePlans(uid);
    dispatch({ type: FETCH_AVAILABLE_PLANS_SUCCESS, payload: plans });
  } catch (error) {
    dispatch({ type: FETCH_AVAILABLE_PLANS_FAILURE, payload: error.message });
  }
};

// Subscribe to a new plan
export const subscribeToPlan = (uid, planType, shopkeeperDetails) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIBE_REQUEST });
    const subscriptionDetails = await subscriptionService.subscribeToPlan(uid, planType, shopkeeperDetails);
    dispatch({ type: SUBSCRIBE_SUCCESS, payload: subscriptionDetails });
  } catch (error) {
    dispatch({ type: SUBSCRIBE_FAILURE, payload: error.message });
  }
};

// Extend the current subscription
export const extendSubscription = (uid, planType, shopkeeperDetails) => async (dispatch) => {
  try {
    dispatch({ type: EXTEND_SUBSCRIPTION_REQUEST });
    const subscriptionDetails = await subscriptionService.extendSubscription(uid, planType, shopkeeperDetails);
    dispatch({ type: EXTEND_SUBSCRIPTION_SUCCESS, payload: subscriptionDetails });
  } catch (error) {
    dispatch({ type: EXTEND_SUBSCRIPTION_FAILURE, payload: error.message });
  }
};
