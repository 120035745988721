// src/redux/actions/printerActions.js
import ServiceLoader from '../../services/ServiceLoader';

const printerService = ServiceLoader.getPrinterService();

// Action Types
export const FETCH_PRINTERS_REQUEST = 'FETCH_PRINTERS_REQUEST';
export const FETCH_PRINTERS_SUCCESS = 'FETCH_PRINTERS_SUCCESS';
export const FETCH_PRINTERS_FAILURE = 'FETCH_PRINTERS_FAILURE';

export const ADD_PRINTER_REQUEST = 'ADD_PRINTER_REQUEST';
export const ADD_PRINTER_SUCCESS = 'ADD_PRINTER_SUCCESS';
export const ADD_PRINTER_FAILURE = 'ADD_PRINTER_FAILURE';

export const UPDATE_PRINTER_CONFIG_REQUEST = 'UPDATE_PRINTER_CONFIG_REQUEST';
export const UPDATE_PRINTER_CONFIG_SUCCESS = 'UPDATE_PRINTER_CONFIG_SUCCESS';
export const UPDATE_PRINTER_CONFIG_FAILURE = 'UPDATE_PRINTER_CONFIG_FAILURE';

export const CHECK_PRINTER_HEALTH_REQUEST = 'CHECK_PRINTER_HEALTH_REQUEST';
export const CHECK_PRINTER_HEALTH_SUCCESS = 'CHECK_PRINTER_HEALTH_SUCCESS';
export const CHECK_PRINTER_HEALTH_FAILURE = 'CHECK_PRINTER_HEALTH_FAILURE';

export const REMOVE_PRINTER_REQUEST = 'REMOVE_PRINTER_REQUEST';
export const REMOVE_PRINTER_SUCCESS = 'REMOVE_PRINTER_SUCCESS';
export const REMOVE_PRINTER_FAILURE = 'REMOVE_PRINTER_FAILURE';

// Action to fetch printers
export const fetchPrinters = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PRINTERS_REQUEST });
    const printers = await printerService.fetchPrinters();
    dispatch({ type: FETCH_PRINTERS_SUCCESS, payload: printers });
  } catch (error) {
    dispatch({ type: FETCH_PRINTERS_FAILURE, payload: error.message });
  }
};

// Action to add a printer
export const addPrinter = (printerName, colorPrinting) => async (dispatch) => {
  try {
    dispatch({ type: ADD_PRINTER_REQUEST });
    const newPrinter = await printerService.addPrinter(printerName, colorPrinting);
    dispatch({ type: ADD_PRINTER_SUCCESS, payload: newPrinter });
  } catch (error) {
    dispatch({ type: ADD_PRINTER_FAILURE, payload: error.message });
  }
};

// Action to update printer configuration
export const updatePrinterConfig = (printerId, config) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRINTER_CONFIG_REQUEST });
    const updatedPrinter = await printerService.updatePrinterConfig(printerId, config);
    dispatch({ type: UPDATE_PRINTER_CONFIG_SUCCESS, payload: updatedPrinter });
  } catch (error) {
    dispatch({ type: UPDATE_PRINTER_CONFIG_FAILURE, payload: error.message });
  }
};

// Action to check printer health
export const checkPrinterHealth = (printerId) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_PRINTER_HEALTH_REQUEST });
    const healthStatus = await printerService.getPrinterHealth(printerId);
    dispatch({ type: CHECK_PRINTER_HEALTH_SUCCESS, payload: { printerId, healthStatus } });
  } catch (error) {
    dispatch({ type: CHECK_PRINTER_HEALTH_FAILURE, payload: error.message });
  }
};

// Action to remove a printer
export const removePrinter = (printerId) => async (dispatch) => {
    try {
      dispatch({ type: REMOVE_PRINTER_REQUEST });
      await printerService.removePrinter(printerId);
      dispatch({ type: REMOVE_PRINTER_SUCCESS, payload: printerId });
    } catch (error) {
      dispatch({ type: REMOVE_PRINTER_FAILURE, payload: error.message });
    }
  };