// src/components/PrinterMgmtModal.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Layer,
  Heading,
  DataTable,
  CheckBox,
  Text,
  TextInput,
} from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPrinters,
  updatePrinterConfig,
  checkPrinterHealth,
  removePrinter,
} from '../redux/actions/printerActions';
import AddPrinterModal from './AddPrinterModal'; // Import the new AddPrinterModal

// Import the icons from grommet-icons
import { StatusGood, StatusWarning, StatusCritical } from 'grommet-icons';

const PrinterMgmtModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { printers, loading, error } = useSelector((state) => state.printer || {});

  const [showAddPrinterModal, setShowAddPrinterModal] = useState(false);
  const [editedPrinters, setEditedPrinters] = useState({});

  useEffect(() => {
    dispatch(fetchPrinters());
  }, [dispatch]);

  // Handle inline editing of printer configurations
  const handleCheckboxChange = (printerId, field, value) => {
    setEditedPrinters((prev) => ({
      ...prev,
      [printerId]: {
        ...prev[printerId],
        [field]: value,
      },
    }));
  };

  // Save updated printer configurations
  const handleSaveConfig = (printerId) => {
    const updatedConfig = editedPrinters[printerId];
    if (updatedConfig) {
      dispatch(updatePrinterConfig(printerId, updatedConfig));
      setEditedPrinters((prev) => {
        const newState = { ...prev };
        delete newState[printerId];
        return newState;
      });
    }
  };

  // Check printer health
  const handleCheckHealth = (printerId) => {
    dispatch(checkPrinterHealth(printerId));
  };

  // Remove a printer
  const handleRemovePrinter = (printerId) => {
    dispatch(removePrinter(printerId));
  };

  // Get the display status icon based on printer status
  const getStatusIcon = (status) => {
    switch (status) {
      case 'OK':
        return <StatusGood color="status-ok" />;
      case 'Warning':
        return <StatusWarning color="status-warning" />;
      case 'Error':
        return <StatusCritical color="status-critical" />;
      default:
        return <StatusGood />;
    }
  };

  // Columns for the DataTable
  const columns = [
    {
      property: 'printerName',
      header: <Text weight="bold">Printer Name</Text>,
      primary: true,
    },
    {
      property: 'status',
      header: <Text weight="bold">Status</Text>,
      render: (datum) => (
        <Box direction="row" align="center" gap="small">
          {getStatusIcon(datum.status)}
          <Text>{datum.status}</Text>
        </Box>
      ),
    },
    {
      property: 'colorPrinting',
      header: <Text weight="bold">Color Support</Text>,
      render: (datum) => (datum.colorPrinting ? 'Yes' : 'No'),
    },
    {
      property: 'forPaidDocs',
      header: <Text weight="bold">For Paid Docs</Text>,
      render: (datum) => (
        <CheckBox
          checked={
            editedPrinters[datum.printerId]?.forPaidDocs ?? datum.forPaidDocs
          }
          onChange={(event) =>
            handleCheckboxChange(
              datum.printerId,
              'forPaidDocs',
              event.target.checked
            )
          }
        />
      ),
    },
    {
      property: 'forUnpaidDocs',
      header: <Text weight="bold">For Unpaid Docs</Text>,
      render: (datum) => (
        <CheckBox
          checked={
            editedPrinters[datum.printerId]?.forUnpaidDocs ?? datum.forUnpaidDocs
          }
          onChange={(event) =>
            handleCheckboxChange(
              datum.printerId,
              'forUnpaidDocs',
              event.target.checked
            )
          }
        />
      ),
    },
    {
      property: 'actions',
      header: <Text weight="bold">Actions</Text>,
      render: (datum) => (
        <Box direction="row" gap="small">
          <Button
            label="Save"
            onClick={() => handleSaveConfig(datum.printerId)}
            disabled={!editedPrinters[datum.printerId]}
            size="small"
          />
          <Button
            label="Check Health"
            onClick={() => handleCheckHealth(datum.printerId)}
            size="small"
          />
          <Button
            label="Remove"
            onClick={() => handleRemovePrinter(datum.printerId)}
            size="small"
            color="status-critical"
          />
        </Box>
      ),
    },
  ];

  return (
    <Layer onEsc={onClose} onClickOutside={onClose} full>
      <Box fill pad="medium">
        <Box direction="row" justify="between" align="center">
          <Heading level="3" margin="none">
            Printer Management
          </Heading>
          <Button label="Close" onClick={onClose} />
        </Box>

        <Box direction="row" justify="end" margin={{ vertical: 'medium' }}>
          <Button
            label="Add New Printer"
            primary
            onClick={() => setShowAddPrinterModal(true)}
          />
        </Box>

        {loading && <Text>Loading printers...</Text>}
        {error && <Text color="status-critical">{error}</Text>}

        <DataTable
          columns={columns}
          data={printers}
          sortable
          size="medium"
          step={10}
          paginate
        />

        {showAddPrinterModal && (
          <AddPrinterModal onClose={() => setShowAddPrinterModal(false)} />
        )}
      </Box>
    </Layer>
  );
};

export default PrinterMgmtModal;
