// src/components/ShopkeeperDetailsModal.js

import React, { useState, useEffect } from 'react';
import { Box, Button, TextInput, FormField, Heading, Layer } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { updateShopkeeperInfo } from '../redux/actions/shopkeeperActions'; // Import the update action

const ShopkeeperDetailsModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { shopkeeperData } = useSelector((state) => state.shopkeeper);  // Fetch current shopkeeper data from store

  // State to hold form inputs
  const [shopName, setShopName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [shopAddress, setShopAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');

  // Populate the form with the current shopkeeper details
  useEffect(() => {
    if (shopkeeperData) {
      setShopName(shopkeeperData.shopName || '');
      setContactNumber(shopkeeperData.contactNumber || '');
      setShopAddress(shopkeeperData.shopAddress || '');
      setCity(shopkeeperData.city || '');
      setPostalCode(shopkeeperData.postalCode || '');
    }
  }, [shopkeeperData]);

  // Handle form submission
  const handleSubmit = () => {
    const updatedData = {
      ...shopkeeperData,
      shopName,
      contactNumber,
      shopAddress,
      city,
      postalCode,
    };

    // Dispatch action to update shopkeeper details
    dispatch(updateShopkeeperInfo(shopkeeperData.uid, updatedData, shopkeeperData.token));

    // Close modal after submission
    onClose();
  };

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium" width="medium">
        <Heading level="3" margin="none">Edit Shop Details</Heading>

        <FormField label="Shop Name">
          <TextInput
            placeholder="Enter your shop name"
            value={shopName}
            onChange={(event) => setShopName(event.target.value)}
          />
        </FormField>

        <FormField label="Contact Number">
          <TextInput
            placeholder="Enter your contact number"
            value={contactNumber}
            onChange={(event) => setContactNumber(event.target.value)}
          />
        </FormField>

        <FormField label="Shop Address">
          <TextInput
            placeholder="Enter your shop address"
            value={shopAddress}
            onChange={(event) => setShopAddress(event.target.value)}
          />
        </FormField>

        <FormField label="City">
          <TextInput
            placeholder="Enter your city"
            value={city}
            onChange={(event) => setCity(event.target.value)}
          />
        </FormField>

        <FormField label="Postal Code">
          <TextInput
            placeholder="Enter your postal code"
            value={postalCode}
            onChange={(event) => setPostalCode(event.target.value)}
          />
        </FormField>

        <Box direction="row" gap="medium" margin={{ top: 'medium' }}>
          <Button label="Save" primary onClick={handleSubmit} />
          <Button label="Cancel" onClick={onClose} />
        </Box>
      </Box>
    </Layer>
  );
};

export default ShopkeeperDetailsModal;
