// src/redux/actions/portalStatsActions.js
import ServiceLoader from "../../services/ServiceLoader";
// Action Types
export const FETCH_PORTAL_STATS_REQUEST = 'FETCH_PORTAL_STATS_REQUEST';
export const FETCH_PORTAL_STATS_SUCCESS = 'FETCH_PORTAL_STATS_SUCCESS';
export const FETCH_PORTAL_STATS_FAILURE = 'FETCH_PORTAL_STATS_FAILURE';

const portalStatisticsService = ServiceLoader.getSubscriptionService()

// Action to fetch portal statistics
export const fetchPortalStatistics = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PORTAL_STATS_REQUEST });
    const stats = await portalStatisticsService.fetchPortalStatistics();
    dispatch({ type: FETCH_PORTAL_STATS_SUCCESS, payload: stats });
  } catch (error) {
    dispatch({ type: FETCH_PORTAL_STATS_FAILURE, payload: error.message });
  }
};
