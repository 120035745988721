// src/services/MockPrinterService.js

export const MockPrinterService = {
    printers: [], // Array of available printers
    printerIdCounter: 1,
  
    // Initialize some mock printers
    initializePrinters: function () {
      this.printers = [
        {
          printerId: 'printer_001',
          printerName: 'HP LaserJet Pro',
          status: 'OK',
          colorPrinting: true,
          forPaidDocs: true, // Handles paid document printing
          forUnpaidDocs: false, // Doesn't handle unpaid document printing
        },
        {
          printerId: 'printer_002',
          printerName: 'Canon Pixma',
          status: 'OK',
          colorPrinting: false,
          forPaidDocs: false, // Doesn't handle paid documents
          forUnpaidDocs: true, // Handles unpaid document printing
        },
      ];
    },
  
    // Simulate adding a new printer
    addPrinter: function (printerName, colorPrinting) {
      const newPrinter = {
        printerId: `printer_${this.printerIdCounter++}`,
        printerName,
        status: 'OK',
        colorPrinting,
        forPaidDocs: false, // Initially not assigned for any task
        forUnpaidDocs: false,
      };
      this.printers.push(newPrinter);
      return newPrinter;
    },
  
    // Simulate fetching all printers
    fetchPrinters: function () {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([...this.printers]); // Return a copy of the printers array
        }, 500);
      });
    },
  
    // Update a printer's configuration (e.g., set for paid/unpaid docs)
    updatePrinterConfig: function (printerId, config) {
      const printerIndex = this.printers.findIndex((printer) => printer.printerId === printerId);
      if (printerIndex !== -1) {
        this.printers[printerIndex] = { ...this.printers[printerIndex], ...config };
        return this.printers[printerIndex];
      }
      throw new Error('Printer not found');
    },
  
    // Simulate updating printer health (e.g., OK, Error, Low Ink)
    updatePrinterHealth: function (printerId, status) {
      const printerIndex = this.printers.findIndex((printer) => printer.printerId === printerId);
      if (printerIndex !== -1) {
        this.printers[printerIndex].status = status;
        return this.printers[printerIndex];
      }
      throw new Error('Printer not found');
    },
  
    // Simulate getting printer health status
    getPrinterHealth: function (printerId) {
      const printer = this.printers.find((printer) => printer.printerId === printerId);
      if (printer) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(printer.status);
          }, 500);
        });
      }
      throw new Error('Printer not found');
    },
  };
  