// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyC5Ma2bPliIaqqiWh1cQNWuUXfQ0r7fw-o",
  authDomain: "ezprints-c5b98.firebaseapp.com",
  projectId: "ezprints-c5b98",
  storageBucket: "ezprints-c5b98.appspot.com",
  messagingSenderId: "895340241824",
  appId: "1:895340241824:web:792d2084b0404c576dbac5",
  measurementId: "G-QSYB55NHGG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
