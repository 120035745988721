// src/redux/actions/shopkeeperActions.js

//import { MockShopkeeperService } from '../../services/MockShopkeeperService';
import ServiceLoader from '../../services/ServiceLoader';

// Action Types
export const STORE_SHOPKEEPER_DATA_REQUEST = 'STORE_SHOPKEEPER_DATA_REQUEST';
export const STORE_SHOPKEEPER_DATA_SUCCESS = 'STORE_SHOPKEEPER_DATA_SUCCESS';
export const STORE_SHOPKEEPER_DATA_FAILURE = 'STORE_SHOPKEEPER_DATA_FAILURE';

export const FETCH_SHOPKEEPER_DATA_REQUEST = 'FETCH_SHOPKEEPER_DATA_REQUEST';
export const FETCH_SHOPKEEPER_DATA_SUCCESS = 'FETCH_SHOPKEEPER_DATA_SUCCESS';
export const FETCH_SHOPKEEPER_DATA_FAILURE = 'FETCH_SHOPKEEPER_DATA_FAILURE';

export const UPDATE_SHOPKEEPER_DATA_REQUEST = 'UPDATE_SHOPKEEPER_DATA_REQUEST';
export const UPDATE_SHOPKEEPER_DATA_SUCCESS = 'UPDATE_SHOPKEEPER_DATA_SUCCESS';
export const UPDATE_SHOPKEEPER_DATA_FAILURE = 'UPDATE_SHOPKEEPER_DATA_FAILURE';

export const FETCH_SHOP_METRICS_REQUEST = 'FETCH_SHOP_METRICS_REQUEST';
export const FETCH_SHOP_METRICS_SUCCESS = 'FETCH_SHOP_METRICS_SUCCESS';
export const FETCH_SHOP_METRICS_FAILURE = 'FETCH_SHOP_METRICS_FAILURE';

// Action Types (Add these new action types)
export const CONFIGURE_RAZORPAY_REQUEST = 'CONFIGURE_RAZORPAY_REQUEST';
export const CONFIGURE_RAZORPAY_SUCCESS = 'CONFIGURE_RAZORPAY_SUCCESS';
export const CONFIGURE_RAZORPAY_FAILURE = 'CONFIGURE_RAZORPAY_FAILURE';

// Action to store shopkeeper data (during registration)
export const storeShopkeeperData = (shopkeeperData, token) => async (dispatch) => {
  try {
    dispatch({ type: STORE_SHOPKEEPER_DATA_REQUEST });
    const shopkeeperService = ServiceLoader.getShopkeeperService();

    await shopkeeperService.registerShopkeeper(shopkeeperData, token);

    dispatch({
      type: STORE_SHOPKEEPER_DATA_SUCCESS,
      payload: shopkeeperData,
    });
  } catch (error) {
    dispatch({ type: STORE_SHOPKEEPER_DATA_FAILURE, payload: error.message });
    throw error;
  }
};

// Action to fetch shopkeeper data
export const fetchShopkeeperData = (uid, token) => async (dispatch) => {
  const shopkeeperService = ServiceLoader.getShopkeeperService();

  try {
    dispatch({ type: FETCH_SHOPKEEPER_DATA_REQUEST });

    const shopkeeperData = await shopkeeperService.getShopkeeperData(uid, token);

    dispatch({
      type: FETCH_SHOPKEEPER_DATA_SUCCESS,
      payload: shopkeeperData,
    });
  } catch (error) {
    dispatch({ type: FETCH_SHOPKEEPER_DATA_FAILURE, payload: error.message });
    throw error;
  }
};

// Action to fetch shop performance metrics
export const fetchShopMetrics = (uid, token) => async (dispatch) => {
  const shopkeeperService = ServiceLoader.getShopkeeperService();

  try {
    dispatch({ type: FETCH_SHOP_METRICS_REQUEST });

    const metrics = await shopkeeperService.getShopPerformanceMetrics(uid, token);

    dispatch({
      type: FETCH_SHOP_METRICS_SUCCESS,
      payload: metrics,
    });
  } catch (error) {
    dispatch({ type: FETCH_SHOP_METRICS_FAILURE, payload: error.message });
    throw error;
  }
};

// Action to update shopkeeper information
export const updateShopkeeperInfo = (uid, updatedData, token) => async (dispatch) => {
  const shopkeeperService = ServiceLoader.getShopkeeperService();

  try {
    dispatch({ type: UPDATE_SHOPKEEPER_DATA_REQUEST });

    await shopkeeperService.updateShopkeeperInfo(uid, updatedData, token);

    dispatch({
      type: UPDATE_SHOPKEEPER_DATA_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    dispatch({ type: UPDATE_SHOPKEEPER_DATA_FAILURE, payload: error.message });
    throw error;
  }
};

// Action to configure Razorpay
export const configureRazorpay = (uid, razorpayDetails) => async (dispatch) => {
  const shopkeeperService = ServiceLoader.getShopkeeperService();

  try {
    dispatch({ type: CONFIGURE_RAZORPAY_REQUEST });
    const response = await shopkeeperService.configureRazorpay(uid, razorpayDetails);
    dispatch({ type: CONFIGURE_RAZORPAY_SUCCESS, payload: response.razorpayConfig });
  } catch (error) {
    dispatch({ type: CONFIGURE_RAZORPAY_FAILURE, payload: error.message });
  }
};