// src/screens/LoginRegistration.js

import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Grid,
  Layer,
  Button,
  ResponsiveContext,
  Image,
} from 'grommet';
import { UserAdd, Login, Shop, Globe, Print, Group, Location } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../components/LoginModal';
import RegistrationModal from '../components/RegistrationModal';

const LoginRegistration = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const navigate = useNavigate(); // Hook for navigation
  const size = React.useContext(ResponsiveContext); // Get the current screen size

  const [stats] = useState({
    registeredShops: Math.floor(Math.random() * 1000) + 100, // Random between 100 and 1100
    cities: Math.floor(Math.random() * 50) + 10, // Random between 10 and 60
    customers: Math.floor(Math.random() * 50000) + 1000, // Random between 1000 and 51000
    printouts: Math.floor(Math.random() * 100000) + 1000, // Random between 1000 and 101000
    countries: Math.floor(Math.random() * 5) + 1, // Random between 1 and 5
  });

  // Function to handle successful login or registration
  const handleSuccess = () => {
    setShowLoginModal(false);
    setShowRegistrationModal(false);
    navigate('/dashboard'); // Navigate to the dashboard
  };

  return (
    <Box fill align="center" justify="start" pad="large">


      {/* Main Content */}
      <Box
        width="xlarge"
        align="center"
        pad={{ horizontal: 'medium', vertical: 'large' }}
        gap="medium"
      >
        {/* Logo and Branding */}
        <Box align="center" gap="small">
          <Image
            src="/assets/logo.png" // Replace with the path to your logo image
            alt="Saral Print Logo"
            fit="contain"
            style={{ width: '150px', height: '150px' }}
          />
          <Heading level="1" margin="none" size={size === 'small' ? 'medium' : 'large'}>
            Saral Print
          </Heading>
          <Text size="xlarge" color="brand">
            Pay, Print, Go!
          </Text>
        </Box>

        {/* Marketing Pitch */}
        <Box align="center" pad={{ vertical: 'medium' }} gap="small">
          <Heading level="2" textAlign="center" margin="none">
            Simplify Printing, Amplify Business!
          </Heading>
          <Text size="large" textAlign="center">
            Join Saral Print and transform your print shop with our all-in-one solution.
          </Text>
          <Text size="large" textAlign="center">
            Manage print jobs effortlessly, accept payments securely, and provide your customers with a seamless experience.
          </Text>
          <Text size="large" textAlign="center">
            Set up print rates, customize your shop profile, and integrate with Razorpay for hassle-free transactions.
          </Text>
        </Box>

        {/* Action Buttons */}
        <Box direction="row" gap="medium" margin={{ top: 'medium' }}>
          <Button
            label={
              <Box direction="row" align="center" gap="small">
                <UserAdd />
                <Text>Register Now</Text>
              </Box>
            }
            onClick={() => setShowRegistrationModal(true)}
            primary
            size="large"
          />
          <Button
            label={
              <Box direction="row" align="center" gap="small">
                <Login />
                <Text>Log In</Text>
              </Box>
            }
            onClick={() => setShowLoginModal(true)}
            secondary
            size="large"
          />
        </Box>

        {/* Stats Section */}
        <Box width="100%" margin={{ top: 'large' }}>
          <Grid
            columns={size !== 'small' ? 'small' : '100%'}
            gap="medium"
            justify="center"
          >
            {/* Registered Shops */}
            <Box
              pad="medium"
              border={{ color: 'light-4', size: 'small' }}
              round="small"
              background="light-1"
              align="center"
            >
              <Shop size="large" color="brand" />
              <Text size="xxlarge" weight="bold">
                {stats.registeredShops}
              </Text>
              <Text>Registered Shops</Text>
            </Box>

            {/* Cities Covered */}
            <Box
              pad="medium"
              border={{ color: 'light-4', size: 'small' }}
              round="small"
              background="light-1"
              align="center"
            >
              <Location size="large" color="brand" />
              <Text size="xxlarge" weight="bold">
                {stats.cities}
              </Text>
              <Text>Cities Covered</Text>
            </Box>

            {/* Countries Covered */}
            <Box
              pad="medium"
              border={{ color: 'light-4', size: 'small' }}
              round="small"
              background="light-1"
              align="center"
            >
              <Globe size="large" color="brand" />
              <Text size="xxlarge" weight="bold">
                {stats.countries}
              </Text>
              <Text>Countries Covered</Text>
            </Box>

            {/* Happy Customers */}
            <Box
              pad="medium"
              border={{ color: 'light-4', size: 'small' }}
              round="small"
              background="light-1"
              align="center"
            >
              <Group size="large" color="brand" />
              <Text size="xxlarge" weight="bold">
                {stats.customers}
              </Text>
              <Text>Happy Customers</Text>
            </Box>

            {/* Printouts Taken */}
            <Box
              pad="medium"
              border={{ color: 'light-4', size: 'small' }}
              round="small"
              background="light-1"
              align="center"
            >
              <Print size="large" color="brand" />
              <Text size="xxlarge" weight="bold">
                {stats.printouts}
              </Text>
              <Text>Printouts Taken</Text>
            </Box>
          </Grid>
        </Box>
      </Box>

      {/* Modals */}
      {showLoginModal && (
        <Layer
          onEsc={() => setShowLoginModal(false)}
          onClickOutside={() => setShowLoginModal(false)}
        >
          <LoginModal onClose={() => setShowLoginModal(false)} onSuccess={handleSuccess} />
        </Layer>
      )}
      {showRegistrationModal && (
        <Layer
          onEsc={() => setShowRegistrationModal(false)}
          onClickOutside={() => setShowRegistrationModal(false)}
        >
          <RegistrationModal
            onClose={() => setShowRegistrationModal(false)}
            onSuccess={handleSuccess}
          />
        </Layer>
      )}
    </Box>
  );
};

export default LoginRegistration;
