// src/services/FirebaseAuthService.js

import { 
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword, 
    sendPasswordResetEmail, 
    signOut,
    signInWithPopup,
    GoogleAuthProvider
  } from 'firebase/auth';
  import { auth } from '../firebase'; // Firebase config and auth initialized here
  
  export const FirebaseAuthService = {
  
    // Register a user using email and password
    registerUser: async (email, password) => {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log("firebase user register", user);
        return {
          uid: user.uid,
          email: user.email,
          idToken: await user.getIdToken(),
        };
      } catch (error) {
        throw new Error(error.message);
      }
    },
  
    // Login user with email and password
    loginUser: async (email, password) => {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log("firebase user login", user);
        return {
          uid: user.uid,
          email: user.email,
          idToken: await user.getIdToken(),
        };
      } catch (error) {
        throw new Error(error.message);
      }
    },
  
    // Signup user using Google Sign-In
    signupWithGoogle: async () => {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        console.log("firebase user signup with google", user);
        return {
          uid: user.uid,
          email: user.email,
          idToken: await user.getIdToken(),
        };
      } catch (error) {
        throw new Error(error.message);
      }
    },
  
    // Login user using Google Sign-In
    signinWithGoogle: async () => {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        console.log("signin result",result)
        const user = result.user;
        return {
          uid: user.uid,
          email: user.email,
          idToken: await user.getIdToken(),
        };
      } catch (error) {
        console.log("signin error",error)

        throw new Error(error.message);
      }
    },
  
    // Send password reset email
    resetPassword: async (email) => {
      try {
        await sendPasswordResetEmail(auth, email);
        return { message: 'Password reset email sent' };
      } catch (error) {
        throw new Error(error.message);
      }
    },
  
    // Log out the user
    logoutUser: async () => {
      try {
        await signOut(auth);
        return { message: 'User logged out successfully' };
      } catch (error) {
        throw new Error(error.message);
      }
    }
  };
  