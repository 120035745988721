// src/screens/Dashboard.js

import React, { useState, useEffect } from 'react';
import { Box, Layer } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DashboardTopBar from '../components/DashboardTopBar';
import SubscribeModal from '../components/SubscribeModal';
import PrinterMgmtModal from '../components/PrinterMgmtModal';
import RateCardMgmtModal from '../components/RateCardMgmtModal';
import WorkingHoursSetupModal from '../components/WorkingHoursSetupModal';
import RazorpayConfigModal from '../components/RazorpayConfigModal';
import ShopkeeperDetailsModal from '../components/ShopkeeperDetailsModal';
import GmailConfigModal from '../components/GmailConfigModal';
import JobQueue from '../components/JobQueue';

import { logout } from '../redux/actions/authActions';
import { fetchShopkeeperData, fetchShopMetrics } from '../redux/actions/shopkeeperActions';  // Fetch actions

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States to control modals
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showPrinterMgmtModal, setShowPrinterMgmtModal] = useState(false);
  const [showRateCardMgmtModal, setShowRateCardMgmtModal] = useState(false);
  const [showWorkingHoursSetupModal, setShowWorkingHoursSetupModal] = useState(false);
  const [showRazorpayConfigModal, setShowRazorpayConfigModal] = useState(false);
  const [shopkeeperDetailsModal, setShopkeeperDetailsModal] = useState(false);
  const [showGmailConfigModal, setShowGmailConfigModal] = useState(false);

  // Fetching shopkeeperData and shopMetrics from Redux store
  const { shopkeeperData, shopMetrics } = useSelector((state) => state.shopkeeper);
  const { user, token } = useSelector((state) => state.auth);  // Assume user and token are in the auth state

  // Effect to fetch shopkeeper data once after component mounts
  useEffect(() => {
    if (user && token) {
      dispatch(fetchShopkeeperData(user.uid, token));
    }
  }, [dispatch, user, token]);

  // Effect to periodically fetch shop metrics every 5 seconds
  useEffect(() => {
    if (user && token) {
      // Fetch metrics immediately, then every 5 seconds
      const fetchMetrics = () => {
        dispatch(fetchShopMetrics(user.uid, token));
      };
      fetchMetrics();  // Fetch immediately
      const intervalId = setInterval(fetchMetrics, 5000);  // Fetch every 5 seconds

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [dispatch, user, token]);


  // Fallbacks for the data

  const earnings = shopMetrics?.earningsToday || 0;
  const pageCount = shopMetrics?.pagesPrintedToday || 0;
  const pendingJobs = shopMetrics?.pendingPrintJobs || 0;
  const alertCount = shopMetrics?.alerts?.length || 0;
  const warningCount = shopMetrics?.lowInkWarning ? 1 : 0;

  
  const handleLogout = () => {
    dispatch(logout());
    navigate('/');  // Navigate back to the login/registration page
  };


  return (
    <Box fill>
      <DashboardTopBar
        shopkeeperData={shopkeeperData}  // Dynamic shopkeeper 
        earnings={earnings}  // Dynamic earnings
        pageCount={pageCount}  // Dynamic page count
        pendingJobs={pendingJobs}  // Dynamic pending jobs
        alertCount={alertCount}  // Dynamic alert count
        warningCount={warningCount}  // Dynamic warning count
        onLogout={handleLogout}
        onSubscribeClick={() => setShowSubscribeModal(true)}  // Open the subscription modal
        onPrinterMgmtClick={() => setShowPrinterMgmtModal(true)}  // Open the Printer Management modal
        onRateCardMgmtClick={() => setShowRateCardMgmtModal(true)}  // Open the Rate Card Management modal
        onWorkingHoursClick={() => setShowWorkingHoursSetupModal(true)}  // Open the Working Hours Setup modal
        onRazorpayConfigClick={() => setShowRazorpayConfigModal(true)}  // Open the Razorpay Configuration modal
        onMyDetailsClick={() => setShopkeeperDetailsModal(true)}
        onGmailConfigClick={() => setShowGmailConfigModal(true)} 
      />

      <Box direction="row" fill="horizontal" gap="medium" pad="medium">
        <JobQueue />
      </Box>

      {/* Modals for Different Management Features */}
      {showSubscribeModal && (
        <Layer onEsc={() => setShowSubscribeModal(false)} onClickOutside={() => setShowSubscribeModal(false)}>
          <SubscribeModal onClose={() => setShowSubscribeModal(false)} />
        </Layer>
      )}
      {showPrinterMgmtModal && (
        <Layer onEsc={() => setShowPrinterMgmtModal(false)} onClickOutside={() => setShowPrinterMgmtModal(false)}>
          <PrinterMgmtModal onClose={() => setShowPrinterMgmtModal(false)} />
        </Layer>
      )}
      {showRateCardMgmtModal && (
        <Layer onEsc={() => setShowRateCardMgmtModal(false)} onClickOutside={() => setShowRateCardMgmtModal(false)}>
          <RateCardMgmtModal onClose={() => setShowRateCardMgmtModal(false)} />
        </Layer>
      )}
      {showWorkingHoursSetupModal && (
        <Layer onEsc={() => setShowWorkingHoursSetupModal(false)} onClickOutside={() => setShowWorkingHoursSetupModal(false)}>
          <WorkingHoursSetupModal onClose={() => setShowWorkingHoursSetupModal(false)} />
        </Layer>
      )}
      {showRazorpayConfigModal && (
        <Layer onEsc={() => setShowRazorpayConfigModal(false)} onClickOutside={() => setShowRazorpayConfigModal(false)}>
          <RazorpayConfigModal onClose={() => setShowRazorpayConfigModal(false)} />
        </Layer>
      )}
      {shopkeeperDetailsModal && (
        <Layer onEsc={() => setShopkeeperDetailsModal(false)} onClickOutside={() => setShopkeeperDetailsModal(false)}>
          <ShopkeeperDetailsModal onClose={() => setShopkeeperDetailsModal(false)} />
        </Layer>
      )}
      {showGmailConfigModal && (
         <Layer onEsc={() => setShowGmailConfigModal(false)} onClickOutside={() => setShowGmailConfigModal(false)}>
                 <GmailConfigModal onClose={() => setShowGmailConfigModal(false)} />
       </Layer>
      )}
    </Box>
  );
};

export default Dashboard;
