import { fetchShopkeeperData, storeShopkeeperData } from './shopkeeperActions';  // Import shopkeeper actions
import { fetchSubscriptionStatus } from './subscriptionActions';  // Import subscription actions
import ServiceLoader from '../../services/ServiceLoader';

// Action Types
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';  // Adding LOGOUT action type

// Action to register a user and store shopkeeper data
export const registerUser = (formData) => async (dispatch) => {
  const authService = ServiceLoader.getAuthService();
  try {
    dispatch({ type: REGISTER_REQUEST });

    // Step 1: Register user with authService
    const authResponse = await authService.registerUser(formData.email, formData.password);
    
    // Step 2: Store shopkeeper data with some default values
    const shopkeeperData = {
      uid: authResponse.uid,
      shopName: formData.shopName || "Default Shop Name",
      email: authResponse.email,
      contactNumber: formData.contactNumber || "Not Provided",  // Example additional data
      address: formData.address || "Default Address",  // Example default value
      printCostPerPage: 0.10,  // Default pricing for registration
      printCostPerColorPage: 0.50,
      doubleSidedPrintingCost: 0.05
    };
    
    // Step 3: Dispatch the storeShopkeeperData action to save the shop data
    await dispatch(storeShopkeeperData(shopkeeperData, authResponse.idToken));

    // Step 4: Dispatch registration success
    dispatch({
      type: REGISTER_SUCCESS,
      payload: {
        user: authResponse,
        token: authResponse.idToken,
      },
    });
  } catch (error) {
    dispatch({ type: REGISTER_FAILURE, payload: error.message });
  }
};


// Action to register a user and store shopkeeper data
export const signupWithGoogle = (formData) => async (dispatch) => {
  const authService = ServiceLoader.getAuthService();
  try {
    dispatch({ type: REGISTER_REQUEST });

    // Step 1: Register user with authService
    const authResponse = await authService.signupWithGoogle();
    
    // Step 2: Store shopkeeper data with some default values
    const shopkeeperData = {
      uid: authResponse.uid,
      shopName: formData.shopName || "Default Shop Name",
      email: authResponse.email,
      contactNumber: formData.contactNumber || "Not Provided",  // Example additional data
      address: formData.address || "Default Address",  // Example default value
      printCostPerPage: 0.10,  // Default pricing for registration
      printCostPerColorPage: 0.50,
      doubleSidedPrintingCost: 0.05
    };
    
    // Step 3: Dispatch the storeShopkeeperData action to save the shop data
    await dispatch(storeShopkeeperData(shopkeeperData, authResponse.idToken));

    // Step 4: Dispatch registration success
    dispatch({
      type: REGISTER_SUCCESS,
      payload: {
        user: authResponse,
        token: authResponse.idToken,
      },
    });
  } catch (error) {
    dispatch({ type: REGISTER_FAILURE, payload: error.message });
  }
};

// Action to log in a user and fetch shopkeeper data
export const loginUser = (email, password) => async (dispatch) => {
  const authService = ServiceLoader.getAuthService();
  try {
    dispatch({ type: LOGIN_REQUEST });

    // Step 1: Log in user with authService
    const authResponse = await authService.loginUser(email, password);
    
    // Step 2: Fetch shopkeeper data using user info
    await dispatch(fetchShopkeeperData(authResponse.uid, authResponse.idToken));

    // Step 4: Fetch subscription details after login
    await dispatch(fetchSubscriptionStatus(authResponse.uid, authResponse.idToken));

    // Step 3: Dispatch login success
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user: authResponse,
        token: authResponse.idToken,
      },
    });

     
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE, payload: error.message });
  }
};



// Action to log in a user and fetch shopkeeper data
export const signinWithGoogle = () => async (dispatch) => {
  const authService = ServiceLoader.getAuthService();
  try {
    dispatch({ type: LOGIN_REQUEST });

    // Step 1: Log in user with authService
    const authResponse = await authService.signinWithGoogle();
    console.log("authResponse",authResponse)
    
    // Step 2: Fetch shopkeeper data using user info
    await dispatch(fetchShopkeeperData(authResponse.uid, authResponse.idToken));

    // Step 4: Fetch subscription details after login
    await dispatch(fetchSubscriptionStatus(authResponse.uid, authResponse.idToken));

    // Step 3: Dispatch login success
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user: authResponse,
        token: authResponse.idToken,
      },
    });

     
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE, payload: error.message });
  }
};

// Action to log out a user
export const logout = () => async (dispatch) => {
  const authService = ServiceLoader.getAuthService();
  try {
    await authService.logoutUser();  // Simulate user logout
    dispatch({ type: LOGOUT });  // Dispatch the LOGOUT action to reset state
  } catch (error) {
    console.error('Logout failed:', error);  // Handle any potential errors (unlikely with a mock service)
  }
};
