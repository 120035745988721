// src/services/MockGmailService.js

export const MockGmailService = {
    // Mock configuration of Gmail account
    configureGmailAccount: async (uid, email, password) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // Simulate a successful configuration
          if (email && password) {
            resolve({
              uid,
              email,
              status: 'configured',
              message: 'Gmail account configured successfully.',
            });
          } else {
            reject({
              status: 'error',
              message: 'Invalid email or password.',
            });
          }
        }, 1000);
      });
    },
  
    // Mock fetching new emails with attachments
    fetchEmailsWithAttachments: async (uid) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          // Simulate fetching emails
          const emails = [
            {
              id: 'email_001',
              from: 'user1@example.com',
              subject: 'Document for printing',
              attachment: {
                fileName: 'document1.pdf',
                content: 'Base64EncodedContent',
              },
              receivedAt: new Date().toISOString(),
            },
            {
              id: 'email_002',
              from: 'user2@example.com',
              subject: 'Print this document',
              attachment: {
                fileName: 'document2.docx',
                content: 'Base64EncodedContent',
              },
              receivedAt: new Date().toISOString(),
            },
          ];
  
          resolve(emails);
        }, 2000); // Simulate delay in fetching emails
      });
    },
  };
  