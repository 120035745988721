// src/screens/PrivacyPolicy.js
import React from 'react';
import { Box, Heading, Paragraph } from 'grommet';
import './PrivacyPolicy.css'; // Import the stylesheet

const PrivacyPolicy = () => {
  return (
    <Box className="privacy-container" align="center" pad="large">
      <Box width="large">
        <Heading level="1">Privacy Policy</Heading>
        <Paragraph>Effective Date: 10-Oct-2024</Paragraph>

        <Paragraph>
          <strong>Saral Print Portal Mobile App</strong> (“we”, “our”, or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by Saral Print Portal Mobile App. This policy applies to our mobile application available on the Google Play Store (the "App") and the services provided by Saral Print Portal (the "Services").
        </Paragraph>

        <Paragraph>
          By using our App or Services, you consent to the data practices described in this policy.
        </Paragraph>

        <Heading level="2">1. Information We Collect</Heading>

        <Paragraph>
          <strong>1.1 Personal Information</strong><br />
          When you use our App, we may collect the following personal information from you:
          <ul>
            <li>
              <strong>User Identification</strong>: Name, email address, phone number, or any login credentials required for user authentication on the Saral Print portal.
            </li>
            <li>
              <strong>Print Document Data</strong>: Documents or files uploaded for printing purposes, which are processed to fulfill print requests.
            </li>
            <li>
              <strong>Payment Information</strong>: Payment details, such as transaction data, when subscribing to services or paying for print jobs (handled through secure third-party services like Razorpay).
            </li>
          </ul>
        </Paragraph>

        <Paragraph>
          <strong>1.2 Automatically Collected Information</strong><br />
          Our App may also collect certain non-personal information automatically, including:
          <ul>
            <li>
              <strong>Device Information</strong>: Device type, operating system version, unique device identifiers, and mobile network information.
            </li>
            <li>
              <strong>Usage Data</strong>: Log information, including your interactions with the app (such as the print services used), the date and time of your request, and error reports if issues arise.
            </li>
            <li>
              <strong>Location Data</strong>: We may collect location data to provide you with nearby printing options.
            </li>
          </ul>
        </Paragraph>

        <Heading level="2">2. How We Use Your Information</Heading>

        <Paragraph>
          We use the information we collect to:
          <ul>
            <li>Facilitate and process print jobs via the Saral Print Portal.</li>
            <li>Provide, operate, and maintain our services, including customer support.</li>
            <li>Improve, personalize, and expand our services, including recommending local print providers.</li>
            <li>Communicate with you about your account, subscription, and service updates.</li>
            <li>Process payments for print jobs or subscriptions.</li>
            <li>Ensure security by monitoring app performance and usage.</li>
            <li>Comply with legal obligations and resolve disputes.</li>
          </ul>
        </Paragraph>

        <Heading level="2">3. Sharing of Information</Heading>

        <Paragraph>
          We do not sell, trade, or rent your personal information to others. However, we may share your information in the following situations:
          <ul>
            <li>
              <strong>Service Providers</strong>: With trusted third-party services (such as payment processors) that assist in delivering the services you’ve requested.
            </li>
            <li>
              <strong>Legal Requirements</strong>: When required to comply with the law or protect the rights and safety of our users, Saral Print Portal, or others.
            </li>
            <li>
              <strong>Business Transfers</strong>: In the event of a merger, acquisition, or sale of assets, we may transfer your information to the new owner as part of the business transition.
            </li>
          </ul>
        </Paragraph>

        <Heading level="2">4. Data Retention</Heading>

        <Paragraph>
          We will retain your personal information only for as long as is necessary for the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.
          <ul>
            <li>
              <strong>Print Document Data</strong>: Document files uploaded for printing purposes will only be retained until the print job is completed or canceled.
            </li>
            <li>
              <strong>Payment Information</strong>: Transaction details will be retained in line with regulatory obligations.
            </li>
          </ul>
        </Paragraph>

        <Heading level="2">5. Data Security</Heading>

        <Paragraph>
          We prioritize the security of your data. We use encryption and secure storage mechanisms to protect your information from unauthorized access or disclosure. However, please note that no method of transmission over the internet is 100% secure.
        </Paragraph>

        <Heading level="2">6. Your Choices</Heading>

        <Paragraph>
          You have certain rights regarding your personal data, including the right to:
          <ul>
            <li>
              <strong>Access and Update</strong>: Request access to your personal information or update your details.
            </li>
            <li>
              <strong>Deletion</strong>: Request deletion of your account or personal information, subject to certain legal obligations.
            </li>
            <li>
              <strong>Opt-Out</strong>: You may opt out of receiving marketing communications from us by following the instructions in those communications.
            </li>
          </ul>
        </Paragraph>

        <Heading level="2">7. Third-Party Services</Heading>

        <Paragraph>
          Our App may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices of such third parties. We recommend reviewing their privacy policies for more information.
        </Paragraph>

        <Heading level="2">8. Children’s Privacy</Heading>

        <Paragraph>
          Our App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected information from a child under 13 without parental consent, we will take steps to delete that information.
        </Paragraph>

        <Heading level="2">9. Changes to this Privacy Policy</Heading>

        <Paragraph>
          We may update this Privacy Policy from time to time. Any changes will be posted within the App, and we will notify you of significant changes where required by law.
        </Paragraph>

        <Heading level="2">10. Contact Us</Heading>

        <Paragraph>
          If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:
          <br />
          <strong>Saral Print Portal Team</strong><br />
          info@saralprint.com<br />
          Mumbai, India
        </Paragraph>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
