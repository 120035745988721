import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import  authReducer  from '../reducers/authReducer';
import shopkeeperReducer from '../reducers/shopkeeperReducer';
import gmailReducer from '../reducers/gmailReducer';
import subscriptionReducer from '../reducers/subscriptionReducer';
import jobQueueReducer from '../reducers/jobQueueReducer';
import printerReducer from '../reducers/printerReducer';
import portalStatsReducer from '../reducers/portalStatsReducer';

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  shopkeeper: shopkeeperReducer,
  gmail: gmailReducer,   // Add gmailReducer to root reducer
  subscription: subscriptionReducer,
  jobQueue: jobQueueReducer,
  printer:  printerReducer,
  portalStats: portalStatsReducer,
});

// Create store with thunk middleware
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;