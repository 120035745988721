// src/redux/reducers/jobQueueReducer.js

import {
    FETCH_JOBS_REQUEST,
    FETCH_JOBS_SUCCESS,
    FETCH_JOBS_FAILURE,
    MANUAL_PRINT_REQUEST,
    MANUAL_PRINT_SUCCESS,
    MANUAL_PRINT_FAILURE,
    UPDATE_JOB_STATES,
  } from '../actions/jobQueueActions';
  
  const initialState = {
    jobs: [],
    loading: false,
    error: null,
  };
  
  const jobQueueReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_JOBS_REQUEST:
      case MANUAL_PRINT_REQUEST:
        return { ...state, loading: true, error: null };
  
      case FETCH_JOBS_SUCCESS:
        return { ...state, jobs: action.payload, loading: false };
  
      case FETCH_JOBS_FAILURE:
      case MANUAL_PRINT_FAILURE:
        return { ...state, error: action.payload, loading: false };
  
      case MANUAL_PRINT_SUCCESS:
        return { ...state, loading: false };
  
      case UPDATE_JOB_STATES:
        return state; // No state change needed here; fetchJobs will update the jobs
  
      default:
        return state;
    }
  };
  
  export default jobQueueReducer;
  