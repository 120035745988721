// src/theme/yellowTheme.js

const yellowTheme = {
  global: {
    colors: {
      brand: 'brown', // Main yellow color for branding
      background: '#FFFBEA', // Light yellow background (base color)
      focus: '#FFD700', // Focus color
      'accent-1': '#FFEA00', // Accent color, yellow shade
      text: '#333333', // Dark text color for contrast
    },
    font: {
      family: 'Arial',
      size: '18px',
      height: '20px',
    },
  },
  button: {
    border: {
      radius: '4px',
    },
    primary: {
      color: '#FFD700', // Primary button in yellow
    },
  },
};

export default yellowTheme;
