// src/redux/actions/jobQueueActions.js
import ServiceLoader from '../../services/ServiceLoader';

const jobQueueService = ServiceLoader.getJobQueueService()

// Action Types
export const FETCH_JOBS_REQUEST = 'FETCH_JOBS_REQUEST';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAILURE = 'FETCH_JOBS_FAILURE';

export const MANUAL_PRINT_REQUEST = 'MANUAL_PRINT_REQUEST';
export const MANUAL_PRINT_SUCCESS = 'MANUAL_PRINT_SUCCESS';
export const MANUAL_PRINT_FAILURE = 'MANUAL_PRINT_FAILURE';

export const UPDATE_JOB_STATES = 'UPDATE_JOB_STATES';

// Initialize Jobs (if needed)
export const initializeJobs = () => (dispatch) => {
  jobQueueService.initializeJobs();
};

// Fetch Jobs
export const fetchJobs = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_JOBS_REQUEST });
    const jobs = await jobQueueService.fetchJobs();
    dispatch({ type: FETCH_JOBS_SUCCESS, payload: jobs });
  } catch (error) {
    dispatch({ type: FETCH_JOBS_FAILURE, payload: error.message });
  }
};

// Manually Print a Job
export const manuallyPrintJob = (jobId) => async (dispatch) => {
  try {
    dispatch({ type: MANUAL_PRINT_REQUEST });
    await jobQueueService.manuallyPrintJob(jobId);
    dispatch({ type: MANUAL_PRINT_SUCCESS });
    dispatch(fetchJobs()); // Refresh job list after manual print
  } catch (error) {
    dispatch({ type: MANUAL_PRINT_FAILURE, payload: error.message });
  }
};

// Update Job States
export const updateJobStates = () => (dispatch) => {
  jobQueueService.updateJobStates();
  dispatch({ type: UPDATE_JOB_STATES });
  dispatch(fetchJobs()); // Refresh job list after updating states
};
