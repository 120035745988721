// src/redux/reducers/gmailReducer.js

import {
    CONFIGURE_GMAIL_REQUEST,
    CONFIGURE_GMAIL_SUCCESS,
    CONFIGURE_GMAIL_FAILURE,
    FETCH_EMAILS_REQUEST,
    FETCH_EMAILS_SUCCESS,
    FETCH_EMAILS_FAILURE,
  } from '../actions/gmailActions';
  
  const initialState = {
    gmailConfig: null,
    emails: [],
    loading: false,
    error: null,
  };
  
  const gmailReducer = (state = initialState, action) => {
    switch (action.type) {
      case CONFIGURE_GMAIL_REQUEST:
      case FETCH_EMAILS_REQUEST:
        return { ...state, loading: true, error: null };
  
      case CONFIGURE_GMAIL_SUCCESS:
        return { ...state, gmailConfig: action.payload, loading: false };
  
      case FETCH_EMAILS_SUCCESS:
        return { ...state, emails: action.payload, loading: false };
  
      case CONFIGURE_GMAIL_FAILURE:
      case FETCH_EMAILS_FAILURE:
        return { ...state, error: action.payload, loading: false };
  
      default:
        return state;
    }
  };
  
  export default gmailReducer;
  