// src/services/MockSubscriptionService.js

import { MockPaymentService } from './MockPaymentService';  // Import the MockPaymentService to handle payments

export const MockSubscriptionService = {
  // Mock getting the current subscription status of a shopkeeper
  getSubscriptionStatus: async (uid) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // Randomly determine if the subscription is expired (50% chance)
        const isExpired = Math.random() > 0.5;
        
        // If expired, set the end date to a past date; otherwise, set it to a future date
        const subscriptionStatus = {
          planType: 'monthly',
          startDate: '2024-01-01',
          endDate: isExpired ? '2024-03-01' : '2025-03-01',  // Expired or Active date
          subscriptionStatus: isExpired ? 'expired' : 'active',
        };

        resolve(subscriptionStatus);
      }, 1000);
    });
  },

  // Mock fetching available plans, can suggest custom plans based on the uid
  getAvailablePlans: async (uid) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const availablePlans = [
          { planType: 'monthly', price: 100 },
          { planType: 'yearly', price: 1000 },
        ];

        // Customize available plans for specific users (as an example)
        if (uid === 'premiumUser') {
          availablePlans.push({ planType: 'premium', price: 1500 });
        }

        resolve(availablePlans);
      }, 1000);
    });
  },

  // Mock subscribing to a plan (with payment integration)
  subscribeToPlan: async (uid, planType, shopkeeperDetails) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Calculate the amount based on the selected plan
        const amount = planType === 'monthly' ? 10000 : 100000;  // Example: ₹100 for monthly and ₹1000 for yearly

        // Call the payment service to handle the payment
        const paymentResponse = await MockPaymentService.pay(amount, planType, shopkeeperDetails);

        // Simulate subscription success after payment
        const subscriptionDetails = {
          planType: planType,
          startDate: new Date().toISOString(),
          endDate: planType === 'monthly' ? addMonths(new Date(), 1) : addMonths(new Date(), 12),
          paymentDetails: paymentResponse,
        };

        resolve(subscriptionDetails);
      } catch (error) {
        // Handle payment failure
        reject({ status: 'failed', message: 'Subscription could not be processed due to payment failure', error });
      }
    });
  },

  // Mock extending the subscription
  extendSubscription: async (uid, planType, shopkeeperDetails) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Calculate the amount based on the selected plan
        const amount = planType === 'monthly' ? 10000 : 100000;  // Example: ₹100 for monthly and ₹1000 for yearly

        // Call the payment service to handle the payment for extending the subscription
        const paymentResponse = await MockPaymentService.pay(amount, planType, shopkeeperDetails);

        // Simulate subscription extension success after payment
        const newEndDate = planType === 'monthly' ? addMonths(new Date(), 1) : addMonths(new Date(), 12);

        resolve({
          planType,
          newEndDate,
          paymentDetails: paymentResponse,
        });
      } catch (error) {
        // Handle payment failure during extension
        reject({ status: 'failed', message: 'Extension could not be processed due to payment failure', error });
      }
    });
  },
};

// Helper function to add months to a date
function addMonths(date, months) {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
}
