// src/components/GmailConfigModal.js

import React, { useState } from 'react';
import { Box, Button, TextInput, FormField, Heading, Layer, Text } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { configureGmailAccount } from '../redux/actions/gmailActions';

const GmailConfigModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth); // Assuming user data is in auth state
  const { gmailConfig, loading, error } = useSelector((state) => state.gmail);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleConfigure = () => {
    if (user) {
      dispatch(configureGmailAccount(user.uid, email, password));
    }
  };

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium" width="medium">
        <Heading level="3" margin="none">Gmail Configuration</Heading>

        {gmailConfig && gmailConfig.status === 'configured' ? (
          <Box margin={{ top: 'medium' }}>
            <Text color="status-ok">{gmailConfig.message}</Text>
            <Button label="Close" onClick={onClose} margin={{ top: 'medium' }} />
          </Box>
        ) : (
          <Box>
            <FormField label="Gmail Address">
              <TextInput
                placeholder="Enter your Gmail address"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormField>

            <FormField label="Password">
              <TextInput
                type="password"
                placeholder="Enter your Gmail password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormField>

            {error && <Text color="status-critical">{error}</Text>}

            <Box direction="row" gap="medium" margin={{ top: 'medium' }}>
              <Button label="Configure" primary onClick={handleConfigure} disabled={loading} />
              <Button label="Cancel" onClick={onClose} />
            </Box>
          </Box>
        )}
      </Box>
    </Layer>
  );
};

export default GmailConfigModal;
