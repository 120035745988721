import React, { useState, useEffect } from 'react';
import { Box, Button, TextInput, FormField, Heading, Layer, Select, CheckBox } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { updateShopkeeperInfo } from '../redux/actions/shopkeeperActions';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const WorkingHoursSetupModal = ({ onClose }) => {
  const dispatch = useDispatch();
  
  const { shopkeeperData } = useSelector((state) => state.shopkeeper);

  // State for start and end times
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [selectedDayOff, setSelectedDayOff] = useState('');
  const [is24Hours, setIs24Hours] = useState(false); // New state for 24-hour checkbox

  // Populate current working hours, days off, and 24-hour checkbox
  useEffect(() => {
    if (shopkeeperData.workingHours) {
      const [start, end] = shopkeeperData.workingHours.split(' - ');
      setStartTime(start || '');
      setEndTime(end || '');
    }
    setIs24Hours(shopkeeperData.fullDay || false);

    if (shopkeeperData.daysOff?.length > 0) {
      setSelectedDayOff(shopkeeperData.daysOff[0]); // Set the first day off as default if available
    }
  }, [shopkeeperData.workingHours, shopkeeperData.daysOff, shopkeeperData.fullDay]);

  const handleSave = () => {
    const updatedWorkingHours = is24Hours ? '' : `${startTime} - ${endTime}`;
    const updatedData = {
      ...shopkeeperData,
      workingHours: updatedWorkingHours,
      daysOff: [selectedDayOff],
      fullDay: is24Hours,
    };

    dispatch(updateShopkeeperInfo(shopkeeperData.uid, updatedData, shopkeeperData.token)); // Dispatch updated data
    onClose(); // Close modal
  };

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium" width="medium">
        <Heading level="3" margin="none">Working Hours Setup</Heading>

        <FormField>
          <CheckBox
            checked={is24Hours}
            onChange={(event) => setIs24Hours(event.target.checked)}
            label="24 hrs"
          />
        </FormField>

        <FormField label="Start Time">
          <TextInput
            placeholder="Enter Start Time (e.g., 9:00 AM)"
            value={startTime}
            onChange={(event) => setStartTime(event.target.value)}
            disabled={is24Hours}
          />
        </FormField>

        <FormField label="End Time">
          <TextInput
            placeholder="Enter End Time (e.g., 6:00 PM)"
            value={endTime}
            onChange={(event) => setEndTime(event.target.value)}
            disabled={is24Hours}
          />
        </FormField>

        <FormField label="Day Off">
          <Select
            options={daysOfWeek}
            value={selectedDayOff}
            onChange={({ value }) => setSelectedDayOff(value)}
            placeholder="Select a day off"
          />
        </FormField>

        <Box direction="row" gap="medium" margin={{ top: 'medium' }}>
          <Button label="Save" primary onClick={handleSave} />
          <Button label="Cancel" onClick={onClose} />
        </Box>
      </Box>
    </Layer>
  );
};

export default WorkingHoursSetupModal;
