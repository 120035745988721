// src/components/SubscriptionModal.js

import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, Text, Layer, RadioButtonGroup } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriptionStatus, extendSubscription } from '../redux/actions/subscriptionActions'; // Import actions

const SubscriptionModal = ({ onClose }) => {
  const dispatch = useDispatch();
  
  // Access state from Redux store, ensuring there's a fallback for undefined states
  const { subscriptionStatus = {}, loading = false, error = null } = useSelector((state) => state.subscription || {});

  const { user } = useSelector((state) => state.auth || {}); // Assume user data is stored in auth state
  
  const [planType, setPlanType] = useState('monthly');

  // Fetch subscription status when the modal is opened
  useEffect(() => {
    if (user) {
      dispatch(fetchSubscriptionStatus(user.uid));
    }
  }, [dispatch, user]);

  // Calculate whether the subscription is expired
  const isSubscriptionExpired = subscriptionStatus?.endDate
    ? new Date(subscriptionStatus.endDate) < new Date()
    : false;

  const handleExtendSubscription = () => {
    dispatch(extendSubscription(user.uid, planType, { shopkeeperName: user.email }));
    onClose();
  };

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium" width="medium" gap="small">
        <Heading level="3">Subscription Details</Heading>

        {loading && <Text>Loading...</Text>}
        {error && <Text color="status-critical">Error: {error}</Text>}

        {!loading && subscriptionStatus && (
          <Box>
            <Text>Plan: {subscriptionStatus.planType || 'N/A'}</Text>
            <Text>
              Subscription Status:{' '}
              {isSubscriptionExpired ? (
                <Text color="status-critical">Expired</Text>
              ) : (
                <Text color="status-ok">Active</Text>
              )}
            </Text>
            <Text>Start Date: {subscriptionStatus.startDate ? new Date(subscriptionStatus.startDate).toLocaleDateString() : 'N/A'}</Text>
            <Text>End Date: {subscriptionStatus.endDate ? new Date(subscriptionStatus.endDate).toLocaleDateString() : 'N/A'}</Text>
            {isSubscriptionExpired && (
              <Box margin={{ top: 'medium' }}>
                <Heading level="4">Extend Subscription</Heading>
                <RadioButtonGroup
                  name="planType"
                  options={['monthly', 'yearly']}
                  value={planType}
                  onChange={(event) => setPlanType(event.target.value)}
                />
                <Button
                  label={`Extend Subscription (${planType})`}
                  primary
                  onClick={handleExtendSubscription}
                  margin={{ top: 'small' }}
                />
              </Box>
            )}
          </Box>
        )}

        {!isSubscriptionExpired && (
          <Box direction="row" gap="medium" margin={{ top: 'medium' }}>
            <Button label="Close" onClick={onClose} />
          </Box>
        )}
      </Box>
    </Layer>
  );
};

export default SubscriptionModal;
