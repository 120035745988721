// src/services/ShopkeeperService.js

export const MockShopkeeperService = {
  // Store less mutable shopkeeper data (during registration)
  registerShopkeeper: async (shopkeeperData, token) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('Shopkeeper data stored:', shopkeeperData);
        resolve(shopkeeperData);
      }, 1000);
    });
  },

  // Retrieve less mutable shopkeeper data (e.g., for settings)
  getShopkeeperData: async (uid, token) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // List of random shop names
        const shopNames = [
          "John's Print Shop",
          "Quick Prints",
          "Express Copy Center",
          "Premier Prints",
          "FastTrack Printing",
          "The Print Station",
          "Paper Express",
          "CityPrint",
          "Snap Print Hub",
          "ProCopy Services"
        ];
  
        // Select a random shop name from the list
        const randomShopName = shopNames[Math.floor(Math.random() * shopNames.length)];
  
        // Mocked less mutable data with random shop name
        const shopkeeperData = {
          uid: uid,
          email: 'shopkeeper@printshop.com',
          shopName: randomShopName,  // Randomized shop name
          contactNumber: '+1-555-123-4567',
          shopAddress: '123 Main Street, New York, NY',
          city: 'New York',
          postalCode: '10001',
          locationCoordinates: { latitude: 40.7128, longitude: -74.0060 },
          printers: [
            {
              printerId: 'printer_001',
              printerName: 'HP LaserJet Pro',
              status: 'online',
              lastServiced: '2024-09-10',
              printerType: 'laser',
              colorPrinting: true
            }
          ],
          printCostPerPage: 0.10,
          printCostPerColorPage: 0.50,
          doubleSidedPrintingCost: 0.05,
          subscriptionPlan: 'monthly',
          subscriptionStatus: 'active',
          subscriptionStartDate: '2024-01-01',
          subscriptionEndDate: '2024-02-01',
          paymentMethods: ['Razorpay', 'Cash'],
          workingHours: '9:00 AM - 6:00 PM',
          daysOff: ['Sunday'],
          fullDay:false
        };
  
        resolve(shopkeeperData);
      }, 1000);
    });
  },
  

  // Update less mutable shopkeeper information
  updateShopkeeperInfo: async (uid, updatedData, token) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('Shopkeeper info updated for UID:', uid, updatedData);
        resolve(updatedData);
      }, 1000);
    });
  },

  // Fetch more volatile shop performance metrics
  getShopPerformanceMetrics: async (uid, token) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Randomize the metrics with sensible ranges
        const performanceMetrics = {
          uid: uid,
          earningsToday: Math.floor(Math.random() * 500) + 50,  // Random earnings between $50 and $550
          totalEarnings: Math.floor(Math.random() * 50000) + 1000,  // Random total earnings between $1,000 and $51,000
          pagesPrintedToday: Math.floor(Math.random() * 500) + 50,  // Random pages printed between 50 and 550
          totalPagesPrinted: Math.floor(Math.random() * 100000) + 5000,  // Random total pages printed between 5,000 and 105,000
          pendingPrintJobs: Math.floor(Math.random() * 10) + 1,  // Random pending jobs between 1 and 10
          alerts: ['Printer offline', 'Low ink level'],  // Fixed alerts for now
          lowInkWarning: Math.random() > 0.5,  // Random boolean for low ink warning
          pendingPayments: []  // Empty for now
        };
        resolve(performanceMetrics);
      }, 1000);
    });
  },
  

  // Update more volatile shop performance metrics
  updateShopPerformanceMetrics: async (uid, metricsData, token) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('Shop performance metrics updated for UID:', uid, metricsData);
        resolve(metricsData);
      }, 1000);
    });
  },

  // Manage printer settings
  managePrinterSettings: async (uid, printerData, token) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('Printer settings updated for UID:', uid, printerData);
        resolve(printerData);
      }, 1000);
    });
  },

  // Handle shopkeeper subscription info
  manageSubscription: async (uid, subscriptionData, token) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('Subscription updated for UID:', uid, subscriptionData);
        resolve(subscriptionData);
      }, 1000);
    });
  },

  configureRazorpay: async (uid, razorpayDetails) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (razorpayDetails.keyId && razorpayDetails.keySecret) {
          // Simulate successful configuration
          resolve({
            uid,
            razorpayConfig: {
              keyId: razorpayDetails.keyId,
              keySecret: razorpayDetails.keySecret,
              status: 'configured',
              message: 'Razorpay configured successfully.',
            },
          });
        } else {
          // Simulate failure due to missing details
          reject({
            status: 'error',
            message: 'Invalid Razorpay details provided.',
          });
        }
      }, 1000);
    });
  },
};
