// src/redux/actions/gmailActions.js
import ServiceLoader from '../../services/ServiceLoader';
const gmailService = ServiceLoader.getGmailService();

// Action Types
export const CONFIGURE_GMAIL_REQUEST = 'CONFIGURE_GMAIL_REQUEST';
export const CONFIGURE_GMAIL_SUCCESS = 'CONFIGURE_GMAIL_SUCCESS';
export const CONFIGURE_GMAIL_FAILURE = 'CONFIGURE_GMAIL_FAILURE';

export const FETCH_EMAILS_REQUEST = 'FETCH_EMAILS_REQUEST';
export const FETCH_EMAILS_SUCCESS = 'FETCH_EMAILS_SUCCESS';
export const FETCH_EMAILS_FAILURE = 'FETCH_EMAILS_FAILURE';

// Action to configure Gmail account
export const configureGmailAccount = (uid, email, password) => async (dispatch) => {
  try {
    dispatch({ type: CONFIGURE_GMAIL_REQUEST });
    const response = await gmailService.configureGmailAccount(uid, email, password);
    dispatch({ type: CONFIGURE_GMAIL_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: CONFIGURE_GMAIL_FAILURE, payload: error.message });
  }
};

// Action to fetch emails with attachments
export const fetchEmailsWithAttachments = (uid) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_EMAILS_REQUEST });
    const emails = await gmailService.fetchEmailsWithAttachments(uid);
    dispatch({ type: FETCH_EMAILS_SUCCESS, payload: emails });
  } catch (error) {
    dispatch({ type: FETCH_EMAILS_FAILURE, payload: error.message });
  }
};
