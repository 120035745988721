// src/components/AddPrinterModal.js

import React, { useState } from 'react';
import { Box, Button, FormField, TextInput, Layer, Heading, CheckBox, Text } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { addPrinter } from '../redux/actions/printerActions';

const AddPrinterModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.printer || {});

  const [printerName, setPrinterName] = useState('');
  const [colorPrinting, setColorPrinting] = useState(false);

  const handleAddPrinter = () => {
    if (printerName) {
      dispatch(addPrinter(printerName, colorPrinting));
      setPrinterName('');
      setColorPrinting(false);
      onClose();
    }
  };

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium" width="medium" gap="small">
        <Heading level="3" margin="none">
          Add New Printer
        </Heading>

        <FormField label="Printer Name" required>
          <TextInput
            placeholder="Enter printer name"
            value={printerName}
            onChange={(event) => setPrinterName(event.target.value)}
          />
        </FormField>

        <CheckBox
          label="Supports Color Printing"
          checked={colorPrinting}
          onChange={(event) => setColorPrinting(event.target.checked)}
        />

        {error && <Text color="status-critical">{error}</Text>}

        <Box direction="row" gap="medium" margin={{ top: 'medium' }}>
          <Button
            label="Add Printer"
            primary
            onClick={handleAddPrinter}
            disabled={loading || !printerName}
          />
          <Button label="Cancel" onClick={onClose} />
        </Box>
      </Box>
    </Layer>
  );
};

export default AddPrinterModal;
