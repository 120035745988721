// src/services/MockPortalStatisticsService.js

export const MockPortalStatisticsService = {
    fetchPortalStatistics: async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          // Mock data simulating portal statistics
          const portalStats = {
            registeredShops: 150, // Number of registered shops
            citiesCovered: 30,    // Number of cities covered
            countriesCovered: 5,  // Number of countries covered
            numberOfUsers: 8000,  // Total number of users
            printoutsTaken: 20000 // Total printouts taken so far
          };
          resolve(portalStats);
        }, 1000); // Simulate network delay
      });
    },
  };
  