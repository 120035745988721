// src/redux/reducers/shopkeeperReducer.js

import {
    STORE_SHOPKEEPER_DATA_REQUEST,
    STORE_SHOPKEEPER_DATA_SUCCESS,
    STORE_SHOPKEEPER_DATA_FAILURE,
    FETCH_SHOPKEEPER_DATA_REQUEST,
    FETCH_SHOPKEEPER_DATA_SUCCESS,
    FETCH_SHOPKEEPER_DATA_FAILURE,
    UPDATE_SHOPKEEPER_DATA_REQUEST,
    UPDATE_SHOPKEEPER_DATA_SUCCESS,
    UPDATE_SHOPKEEPER_DATA_FAILURE,
    FETCH_SHOP_METRICS_REQUEST,
    FETCH_SHOP_METRICS_SUCCESS,
    FETCH_SHOP_METRICS_FAILURE,
    CONFIGURE_RAZORPAY_REQUEST,
    CONFIGURE_RAZORPAY_SUCCESS,
    CONFIGURE_RAZORPAY_FAILURE
  } from '../actions/shopkeeperActions';
  
  const initialState = {
    loading: false,
    shopkeeperData: null,
    shopMetrics: null,
    razorpayConfig: null,
    error: null,
  };
  
  const shopkeeperReducer = (state = initialState, action) => {
    switch (action.type) {
      case STORE_SHOPKEEPER_DATA_REQUEST:
      case FETCH_SHOPKEEPER_DATA_REQUEST:
      case UPDATE_SHOPKEEPER_DATA_REQUEST:
      case FETCH_SHOP_METRICS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case STORE_SHOPKEEPER_DATA_SUCCESS:
      case FETCH_SHOPKEEPER_DATA_SUCCESS:
      case UPDATE_SHOPKEEPER_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          shopkeeperData: action.payload,
          error: null,
        };
      case FETCH_SHOP_METRICS_SUCCESS:
        return {
          ...state,
          loading: false,
          shopMetrics: action.payload,
          error: null,
        };
      case STORE_SHOPKEEPER_DATA_FAILURE:
      case FETCH_SHOPKEEPER_DATA_FAILURE:
      case UPDATE_SHOPKEEPER_DATA_FAILURE:
      case FETCH_SHOP_METRICS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case CONFIGURE_RAZORPAY_REQUEST:
          return { ...state, loading: true, error: null };
        case CONFIGURE_RAZORPAY_SUCCESS:
          return { ...state, razorpayConfig: action.payload, loading: false };
        case CONFIGURE_RAZORPAY_FAILURE:
          return { ...state, error: action.payload, loading: false };
      default:
        return state;
    }
  };
  
  export default shopkeeperReducer;
  