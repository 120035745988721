// src/components/RazorpayConfigModal.js

import React, { useState, useEffect } from 'react';
import { Box, Button, TextInput, FormField, Heading, Layer, Text } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { configureRazorpay } from '../redux/actions/shopkeeperActions';

const RazorpayConfigModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth || {});
  const { razorpayConfig, loading, error } = useSelector((state) => state.shopkeeper || {});

  const [keyId, setKeyId] = useState('');
  const [keySecret, setKeySecret] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    // If Razorpay is already configured, populate the fields
    if (razorpayConfig) {
      setKeyId(razorpayConfig.keyId || '');
      setKeySecret(razorpayConfig.keySecret || '');
    }
  }, [razorpayConfig]);

  const handleConfigure = () => {
    if (user) {
      dispatch(configureRazorpay(user.uid, { keyId, keySecret }));
      setMessage(''); // Reset message
    }
  };

  useEffect(() => {
    // Show success message after successful configuration
    if (razorpayConfig && razorpayConfig.status === 'configured' && !loading) {
      setMessage('Razorpay configuration updated successfully.');
    }
  }, [razorpayConfig, loading]);

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium" width="medium">
        <Heading level="3" margin="none">
          Razorpay Configuration
        </Heading>

        <Box margin={{ top: 'medium' }}>
          {message && <Text color="status-ok">{message}</Text>}

          <FormField label="Razorpay Key ID">
            <TextInput
              placeholder="Enter your Razorpay Key ID"
              value={keyId}
              onChange={(event) => setKeyId(event.target.value)}
            />
          </FormField>

          <FormField label="Razorpay Key Secret">
            <TextInput
              type="password"
              placeholder="Enter your Razorpay Key Secret"
              value={keySecret}
              onChange={(event) => setKeySecret(event.target.value)}
            />
          </FormField>

          {error && <Text color="status-critical">{error}</Text>}

          <Box direction="row" gap="medium" margin={{ top: 'medium' }}>
            <Button
              label={razorpayConfig ? 'Update Configuration' : 'Configure'}
              primary
              onClick={handleConfigure}
              disabled={loading || !keyId || !keySecret}
            />
            <Button label="Cancel" onClick={onClose} />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};

export default RazorpayConfigModal;
