// src/navigation/Navigation.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';  // Import Navigate for redirection
import { useSelector } from 'react-redux';  // Import useSelector to get auth state
import LoginRegistration from '../screens/LoginRegistration';
import Dashboard from '../screens/Dashboard';
import PrivacyPolicy from '../screens/PrivacyPolicy'; 


const Navigation = () => {
  // Access the authentication state
  const { user } = useSelector((state) => state.auth);

  return (
    <Routes>
      {/* If user is not logged in, always redirect to root */}
      <Route path="/" element={<LoginRegistration />} />
      <Route
        path="/dashboard"
        element={user ? <Dashboard /> : <Navigate to="/" />}  // Redirect to root if not authenticated
      />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default Navigation;
