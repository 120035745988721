// src/components/RateCardMgmtModal.js

import React, { useState, useEffect } from 'react';
import { Box, Button, TextInput, FormField, Heading, Layer } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { updateShopkeeperInfo } from '../redux/actions/shopkeeperActions'; // Import the update action


const RateCardMgmtModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { shopkeeperData } = useSelector((state) => state.shopkeeper);  // Fetch current shopkeeper data from store


  // State to hold form inputs
  const [printCostPerPage, setPrintCostPerPage] = useState('');
  const [doubleSidedPrintingCost, setDoubleSidedPrintingCost] = useState('');
  const [printCostPerColorPage, setprintCostPerColorPage] = useState(''); // New color page price state

  // Populate the form with the current rate card details
  useEffect(() => {
    if (shopkeeperData) {
      setPrintCostPerPage(shopkeeperData.printCostPerPage || '');
      setDoubleSidedPrintingCost(shopkeeperData.doubleSidedPrintingCost || '');
      setprintCostPerColorPage(shopkeeperData.printCostPerColorPage || ''); // Populate color page price
    }
  }, [shopkeeperData]);

  // Handle form submission
  const handleSave = () => {
    const updatedData = {
      ...shopkeeperData,
      printCostPerPage,
      doubleSidedPrintingCost,
      printCostPerColorPage, // Include color page price in update
    };

    // Dispatch action to update rate card details
    dispatch(updateShopkeeperInfo(updateShopkeeperInfo.uid, updatedData, updateShopkeeperInfo.token));

    // Close modal after submission
    onClose();
  };

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium" width="medium">
        <Heading level="3" margin="none">Rate Card Management</Heading>

        <FormField label="Price per Page">
          <TextInput
            placeholder="Enter Price per Page"
            value={printCostPerPage}
            onChange={(event) => setPrintCostPerPage(event.target.value)}
          />
        </FormField>

        <FormField label="Double-Sided Price">
          <TextInput
            placeholder="Enter Price for Double-Sided Print"
            value={doubleSidedPrintingCost}
            onChange={(event) => setDoubleSidedPrintingCost(event.target.value)}
          />
        </FormField>

        <FormField label="Color Page Price">
          <TextInput
            placeholder="Enter Price for Color Page"
            value={printCostPerColorPage}
            onChange={(event) => setprintCostPerColorPage(event.target.value)}
          />
        </FormField>

        <Box direction="row" gap="medium" margin={{ top: 'medium' }}>
          <Button label="Save" primary onClick={handleSave} />
          <Button label="Cancel" onClick={onClose} />
        </Box>
      </Box>
    </Layer>
  );
};

export default RateCardMgmtModal;
