// src/components/JobQueue.js

import React, { useEffect, useState } from 'react';
import { Box, Button, DataTable, TextInput, Select, Heading } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import ServiceLoader from '../services/ServiceLoader';

import {
  fetchJobs,
  manuallyPrintJob,
  initializeJobs,
  updateJobStates,
} from '../redux/actions/jobQueueActions';


const jobQueueService = ServiceLoader.getJobQueueService()

const JobQueue = () => {
  const dispatch = useDispatch();
  const { jobs, loading, error } = useSelector((state) => state.jobQueue || {});

  const [filterText, setFilterText] = useState('');
  const [filterState, setFilterState] = useState('All');

  useEffect(() => {
    dispatch(initializeJobs());
    dispatch(fetchJobs());

    // Add random jobs every 10 seconds (simulate new documents arriving)
    const addJobInterval = setInterval(() => {
      jobQueueService.addRandomJob();
      dispatch(fetchJobs());
    }, 10000);

    // Update job states every 5 seconds
    const updateStatesInterval = setInterval(() => {
      dispatch(updateJobStates());
    }, 5000);

    return () => {
      clearInterval(addJobInterval);
      clearInterval(updateStatesInterval);
    };
  }, [dispatch]);

  // Filtering the jobs based on filterText and filterState
  const filteredJobs = jobs.filter((job) => {
    const matchesText = job.documentName.toLowerCase().includes(filterText.toLowerCase());
    const matchesState =
      filterState === 'All' ? true : job.state.toLowerCase() === filterState.toLowerCase();
    return matchesText && matchesState;
  });

  const handleManualPrint = (jobId) => {
    dispatch(manuallyPrintJob(jobId));
  };

  const columns = [
    {
      property: 'id',
      header: 'Job ID',
      primary: true,
    },
    {
      property: 'documentName',
      header: 'Document Name',
    },
    {
      property: 'userName',
      header: 'User Name',  // Display the user name
    },
    {
      property: 'source',
      header: 'Source',
    },
    {
      property: 'isPaid',
      header: 'Paid',
      render: (datum) => (datum.isPaid ? 'Yes' : 'No'),
    },
    {
      property: 'state',
      header: 'State',
    },
    {
      property: 'receivedAt',
      header: 'Received At',
      render: (datum) => new Date(datum.receivedAt).toLocaleTimeString(),
    },
    {
      property: 'actions',
      header: 'Actions',
      render: (datum) =>
        !datum.isPaid && datum.state === 'Unpaid' ? (
          <Button label="Print" onClick={() => handleManualPrint(datum.id)} />
        ) : null,
    },
  ];

  return (
    <Box fill="horizontal" pad="medium" background="yellow">
      <Heading level="3" margin="none">
        Job Queue
      </Heading>

      <Box direction="row" gap="medium" margin={{ vertical: 'medium' }}>
        <TextInput
          placeholder="Search by Document Name"
          value={filterText}
          onChange={(event) => setFilterText(event.target.value)}
        />
        <Select
          options={['All', 'Waiting', 'Printing', 'Complete', 'Unpaid']}
          value={filterState}
          onChange={({ option }) => setFilterState(option)}
        />
      </Box>

      {loading && <div>Loading jobs...</div>}
      {error && <div>Error: {error}</div>}

      <DataTable
        columns={columns}
        data={filteredJobs}
        sortable
        size="medium"
        paginate
        step={10}
      />
    </Box>
  );
};

export default JobQueue;
